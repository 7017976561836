body {
  margin: 0;
  font-family: -apple-system, "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #272C35;
}

.container {
  width: 50%;
  max-width: 550px;
  margin: 0 auto;
  padding-top: 20%;
  text-align: left;
}

a {
  color: #e4e4e4;
}

h1 {
  font-size: 1.1em;
  margin: 0;
  color: #45B0F5;
  font-weight: 500;
  line-height: 1.7em;
}

p {
  font-size: 1em;
  line-height: 1.4em;
  margin: 0;
  color: #AAB2C0;
  font-weight: 500;
}

